@use '@queries' as *;

.content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(237px, 1fr));
  gap: 12px;

  @include min-1440-break {
    grid-template-columns: repeat(auto-fill, minmax(301px, 1fr));
  }

  @include min-1920-break {
    grid-template-columns: repeat(auto-fill, minmax(391px, 1fr));
  }
}
